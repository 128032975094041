 .main-workplace.research{
    justify-content: center;
}


.research-wrapper{
    display: flex;
    padding: 40px 0;
}

.research-left, .research-right{
    flex: 0 0 50%;
    max-width: 50%;
}

.research-left{
    text-align: center;
    padding: 0 80px;

    border-right: 3px solid #AA8B6F;
    border-radius: 2px;
}

.research-left .research-img img{
    width: 100%;
    height: 100%;
}

.research-left ul{
    flex-direction: column;
    text-align: center;
}

.research-left ul li{
    color: #815F50;
    font-size: 20px;
}

.research-btn{
    margin-top: 40px;
}

.research-left button{
    border: 0;
    background: #12B124;
    border-radius: 10px;
    color: #fff;
    padding: 10px 30px;
    font-size: 20px;
    cursor: pointer;
}

.research-right{
    text-align: center;
    padding: 0 80px;
    border-left: 3px solid #AA8B6F;
    border-radius: 2px;
}

.research_r_title{
    background: url('../../images/orange_title.png') no-repeat center;
    background-size: contain;
    margin-bottom: 40px;
    padding: 20px;
    position: relative;
}

.research_r_title img{
    position: absolute;
    top: 4%;
    right: 2%;
}

.research_r_title h3{
    color: #F1D8B1;
    font-size: 28px;
    font-weight: 700;

}

.research_r_img img{
    width: 100%;
    height: 100%;
}

.research_r_stats{
    margin-bottom: 55px;
}

.research_r_stats span{
    font-size: 18px;
    font-weight: 400;
}

.research_r_btn{
    margin-top: 50px;
}

.research_r_btn button{
    border: 0;
    background: #12b12567;
    border-radius: 10px;
    color: #fff;
    padding: 10px 30px;
    font-size: 20px;
    cursor: no-drop;
}

@media screen and (max-width: 1400px) {
    .research_r_title{
        margin-bottom: 0;
    }

    .research_r_stats{
        margin-bottom: 0;
    }

    .research_r_btn{
        margin-top: 20px;
    }
}

