
/* *{
    overflow: hidden;
} */

.main{
    width: 100%;
    height: 100vh;
    background: url('../../images/start_to_play_background.png') no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    position: relative;
    
}



.main-up{
    padding-top: 3%;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    
}

.main-up img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.main-btn{
    display: flex;
}

.main-btn a{
    font-size: 2rem;
    display: inline-block;
    background: linear-gradient(180deg, #0EC823 0%, #0E824A 100%);
    padding: 10px 35px;
    border-radius: 10px;
    text-align: center;
    margin: 10% auto;
    transition: all 1s;
}

.main-btn a:hover{
    background: linear-gradient(180deg, #30f747 0%, #1ab369 100%);
    
}

@media screen and (max-width: 768px) {
    .navbar-left ul, .navbar-right{
        display: none;
    }
    
}



