.modal {
    font-size: 12px;
    background: url('../images/modal.png');
    height: 100%;
    width: 100%;
    color:#815F50;
    padding: 30px;
  }
  .modal > .header-modal {
    width: 100%;
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    padding: 5px 0 25px 0;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
  }

  .modal > .content .content-wrapper{
      background: #B29476;
  }

  .content-card-list{
      display: flex;
      padding: 30px;
     
  }

  .modal > .content .card-item{
    width: 140px;
    height: 200px;
    text-align: center;
    margin-left: 10px;
    background: #6b4c3ec4;
    color: #000;
    border-radius: 10px;
    cursor: pointer;
}

.modal > .content .card-item:hover{
    border: 3px solid #3BD946;
}

.modal > .content .card-item__wrapper{
    padding: 10px;
}

.modal > .content .card-item__img{
    width: 90px;
    height: 90px;
    margin: 10px auto; 
}

.modal > .content .card-item__img img{
    width: 100%;
}

  .modal > .content img{
    padding: 5px 0 25px 0;
  }

  .modal > .content .card-item .card-item__content{
    font-size: 18px;
}

  .modal > .actions {
    width: 100%;
    margin: auto;
    text-align: center;
    margin: 40px auto 0 0;
  }

  .modal > .actions a{
    font-size: 24px;
    font-weight: bold;
    background: #12B124;
    border-radius: 10px;
    padding: 5px 40px;
  }

  
.modal > .close{
    cursor: pointer;
    position: absolute;
    right: 4%;
    top: 4%;
}

 

.popup-overlay {
    background: rgba(0, 0, 0, 0.822);
}

.popup-content {
    max-width: 900px;
    height: 507px;
}