*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    overflow: auto;
    height: 100%;
}

a{
    color: #fff;
    text-decoration: none;
}

li{
    list-style: none;
}

.btn-play{
    background: linear-gradient(180deg, #0EC823 0%, #0E824A 100%);
    padding: 10px 25px;
    border-radius: 10px;
}     